var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{staticClass:"table-shadow",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"paginate":true,"lastPage":_vm.lastPage,"sort-by":"tipo-fechamento"},on:{"pageChange":function($event){return _vm.fetchTiposFechamento(_vm.filters, $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Lista de Períodos de Fechamento")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('RegisterBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(116),expression:"116"}],on:{"click":function($event){(_vm.dialog = true), (_vm.labelBtn = 'Salvar')}}}),_c('Filtro',{on:{"selectedFilters":function($event){_vm.fetchTiposFechamento((_vm.filters = $event))}}})],1)]},proxy:true},{key:"item.tipo_evento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.tipo_evento))+" ")]}},{key:"item.id_tipo_fechamento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id_tipo_fechamento)+" ")]}},{key:"item.mes_ref",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("mes")(item.mes_ref))+" ")]}},{key:"item.data_inicio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianStandardDate")(item.data_inicio))+" ")]}},{key:"item.data_final",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianStandardDate")(item.data_final))+" ")]}},{key:"item.ativo",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusFormated(item).color,"dark":""},on:{"click":function($event){return _vm.updateStatus(item)}}},[_vm._v(" "+_vm._s(_vm.statusFormated(item).text)+" ")])]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('IconBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(117),expression:"117"}],attrs:{"name":'edit'},on:{"click":function($event){return _vm.editItem(item)}}}),_c('IconBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(119),expression:"119"}],attrs:{"name":'delete'},on:{"click":function($event){return _vm.deleteItem(item)}}})]}}],null,true)}),_c('ModalEditTipoFechamento',{attrs:{"tipoFechamento":_vm.tipoFechamento,"dialog":_vm.dialog,"labelBtn":_vm.labelBtn},on:{"close":function($event){return _vm.refresh()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }