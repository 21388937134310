<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="items"
      :loading="loading"
      :paginate="true"
      :lastPage="lastPage"
      sort-by="tipo-fechamento"
      @pageChange="fetchTiposFechamento(filters, $event)"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Lista de Períodos de Fechamento</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <RegisterBottom
            v-can-access="116"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
          <Filtro @selectedFilters="fetchTiposFechamento((filters = $event))" />
        </v-toolbar>
      </template>

      <template v-slot:[`item.tipo_evento`]="{ item }">
        {{ item.tipo_evento | TitleCase }}
      </template>

      <template v-slot:[`item.id_tipo_fechamento`]="{ item }">
        {{ item.id_tipo_fechamento }}
      </template>

      <template v-slot:[`item.mes_ref`]="{ item }">
        {{ item.mes_ref | mes }}
      </template>

      <template v-slot:[`item.data_inicio`]="{ item }">
        {{ item.data_inicio | BrazilianStandardDate }}
      </template>

      <template v-slot:[`item.data_final`]="{ item }">
        {{ item.data_final | BrazilianStandardDate }}
      </template>

      <template v-slot:[`item.ativo`]="{ item }">
        <v-chip
          :color="statusFormated(item).color"
          @click="updateStatus(item)"
          dark
        >
          {{ statusFormated(item).text }}
        </v-chip>
      </template>

      <template v-slot:[`item.acoes`]="{ item }">
        <IconBottom :name="'edit'" v-can-access="117" @click="editItem(item)" />
        <IconBottom
          :name="'delete'"
          v-can-access="119"
          @click="deleteItem(item)"
        />
      </template>
    </BaseTable>
    <ModalEditTipoFechamento
      :tipoFechamento="tipoFechamento"
      :dialog="dialog"
      :labelBtn="labelBtn"
      @close="refresh()"
    />
  </div>
</template>

<script>
import BaseTable from "@/components/shared/NewBaseTable";
import IconBottom from "@/components/shared/bottons/IconBottom";
import Filtro from "./Filtro";
import ModalEditTipoFechamento from "./ModalEditTipoFechamento";
import comissao from "@/services/http/comissaoService";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import dateMixin from "@/mixins/dateMixin";

export default {
  name: "TablePeriodoFechamento",

  components: {
    BaseTable,
    Filtro,
    ModalEditTipoFechamento,
    RegisterBottom,
    IconBottom
  },
  mixins: [dateMixin],

  filters: {
    mes(numeroMes) {
      const meses = {
        1: "Janeiro",
        2: "Fevereiro",
        3: "Março",
        4: "Abril",
        5: "Maio",
        6: "Junho",
        7: "Julho",
        8: "Agosto",
        9: "Setembro",
        10: "Outubro",
        11: "Novembro",
        12: "Dezembro"
      };
      return meses[numeroMes] || numeroMes;
    }
  },

  data() {
    return {
      dialog: false,
      filters: {},
      labelBtn: "",
      tipoFechamento: {},
      items: [],
      headers: [
        {
          text: "Codigo Fechamento",
          value: "id_tipo_fechamento"
        },
        {
          text: "Tipo de Evento",
          value: "tipo_evento"
        },
        {
          text: "Tipo de Dado",
          value: "tipo_dado"
        },
        {
          text: "Tipo",
          value: "tipo"
        },
        {
          text: "Mês Referência",
          value: "mes_ref"
        },
        {
          text: "Ano Referência",
          value: "ano_ref"
        },
        {
          text: "Data Inicio",
          value: "data_inicio"
        },
        {
          text: "Data Final",
          value: "data_final"
        },
        {
          text: "Status",
          value: "ativo"
        },
        {
          text: "Ações",
          value: "acoes",
          sortable: false,
          align: "center"
        }
      ],
      loading: true,
      lastPage: 0
    };
  },

  methods: {
    editItem(item) {
      this.tipoFechamento = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },

    async fetchTiposFechamento(filters, nPage = 1) {
      this.loading = true;
      const { data } = await comissao()
        .tipoFechamento()
        .show({
          page: nPage,
          ativo: "S",
          ...filters
        });
      this.lastPage = data.last_page;

      this.items = data.data;
      this.loading = false;
    },

    refresh() {
      this.dialog = false;
      this.fetchTiposFechamento(this.filters);
    },

    statusFormated(tipoFechamento) {
      return {
        color: tipoFechamento.ativo === "S" ? "green" : "red",
        text: tipoFechamento.ativo === "S" ? "Ativo" : "Inativo"
      };
    },

    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          `Deseja deletar periodos de fechamento ${item.id_tipo_fechamento}?`,
          "Essa ação não pode ser desfeita"
        );
        comissao()
          .tipoFechamento(item.id_tipo_fechamento)
          .delete(
            {},
            {
              notification: true,
              message: "Fechamento deletado com sucesso!"
            }
          );
      } catch (error) {
        console.log(error);
      } finally {
        this.fetchTiposFechamento(this.filters);
      }
    },

    async updateStatus(item) {
      await this.$swal.confirm(
        "Editar status da função",
        `Deseja ${item.ativo == "S" ? "Desativar" : "Ativar"} a funcao ${
          item.descricao
        }?`
      );
      item.ativo = item.ativo == "S" ? "N" : "S";
      const fechamento = {
        id_tipo_fechamento: item.id_tipo_fechamento,
        ativo: item.ativo
      };
      await comissao()
        .tipoFechamento(fechamento.id_tipo_fechamento)
        .update(fechamento, {
          notification: true,
          message: "Fechamento Editado com Sucesso!"
        });
    }
  },
  async mounted() {
    await this.fetchTiposFechamento();
  }
};
</script>
