<template>
  <v-dialog v-model="dialog" persistent max-width="70%">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            {{
              labelBtn != "Salvar"
                ? "Editar Fechamento"
                : "Cadastrar Fechamento"
            }}
          </span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row align="center">
            <v-col md="4" lg="4" cols="12">
              <BaseSelect
                label="Tipo Dado"
                v-model="form.id_tipo_dado"
                service="comissaoService.tipoDados"
                item-text="descricao"
                item-value="id_tipo_dado"
                prepend-inner-icon="mdi-calendar-end"
                no-data-text="Nenhum dado encontrado"
                clearable
                :rules="[rules.required]"
                :filters="{ ativa_campanha: 'S' }"
                :multiple="false"
                @change="formatDataInicioDataFim()"
              />
            </v-col>
            <v-col md="4" lg="4" cols="12">
              <BaseSelect
                label="Tipo Evento"
                v-model="form.id_tipo_evento"
                service="comissaoService.tipoEvento"
                item-text="tipo_evento"
                item-value="id_tipo_evento"
                prepend-inner-icon="mdi-calendar-text"
                clearable
                :rules="[rules.required]"
              >
              </BaseSelect>
            </v-col>
            <v-col lg="4" md="4" cols="12">
              <v-text-field
                label="Tipo"
                v-model="form.id_tipo_evento"
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-col>
            <v-col v-show="form.id_tipo_dado === 'C'" md="12" lg="12" cols="12">
              <CalendarFechamentoSemanal
                @intervals="getIntervals($event)"
                @getMonth="monthSelected($event)"
              />
            </v-col>
            <v-col v-show="form.id_tipo_dado === 'X'" md="12" lg="12" cols="12">
              <CalendarFechamentoPersonalizado
                :tipoDado="form.id_tipo_dado"
                @periodoFechamento="getPeriodoFechamento($event)"
              />
              <!-- <v-date-picker
                v-model="dates"
                full-width
                elevation="1"
                locale="pt-BR"
                type="date"
                :title-date-format="formatTitleRangeDate"
                :disabled="disabledDatePicker"
                range
                @change="formatDataInicioDataFimRange()"
              ></v-date-picker> -->
            </v-col>
            <v-col v-show="form.id_tipo_dado === 'R'" md="12" lg="12" cols="12">
              <CalendarFechamentoMesFechado
                :tipoDado="form.id_tipo_dado"
                @periodoFechamento="getPeriodoFechamento($event)"
              />
              <!-- <v-date-picker
                v-model="picker"
                full-width
                elevation="1"
                locale="pt-BR"
                type="month"
                :title-date-format="formatTitleDatePicker"
                :disabled="disabledDatePicker"
                @change="formatDataInicioDataFim()"
              ></v-date-picker> -->
            </v-col>
            <v-col v-show="form.id_tipo_dado === 'M'" md="12" lg="12" cols="12">
              <CalendarFechamentoMesRef
                :tipoDado="form.id_tipo_dado"
                @periodoFechamento="getPeriodoFechamento($event)"
              />
              <!-- <v-date-picker
                v-model="picker"
                full-width
                elevation="1"
                locale="pt-BR"
                type="month"
                :title-date-format="formatTitleDatePicker"
                :disabled="disabledDatePicker"
                @change="formatDataInicioDataFim()"
              ></v-date-picker> -->
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close()">Fechar</v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="labelBtn != 'Salvar' ? edit() : save()"
          >{{ labelBtn }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  parse,
  format,
  lastDayOfMonth,
  subMonths,
  setDate,
  compareAsc,
  getMonth,
  getYear
} from "date-fns";
import { ptBR } from "date-fns/locale";
import rules from "@/mixins/inputRules";
import BaseSelect from "@/components/shared/BaseSelectService";
import comissao from "@/services/http/comissaoService";
import comparator from "@/mixins/comparatorValue";
import CalendarFechamentoMesFechado from "./components/CalendarFechamentoMesFechado";
import CalendarFechamentoMesRef from "./components/CalendarFechamentoMesRef";
import CalendarFechamentoPersonalizado from "./components/CalendarFechamentoPersonalizado";
import CalendarFechamentoSemanal from "./components/CalendarFechamentoSemanal";

export default {
  name: "ModalEditTipoFechamento",
  mixins: [rules, comparator],

  components: {
    BaseSelect,
    CalendarFechamentoMesFechado,
    CalendarFechamentoMesRef,
    CalendarFechamentoPersonalizado,
    CalendarFechamentoSemanal
  },

  props: {
    tipoFechamento: {
      type: Object,
      default: () => ({})
    },
    dialog: {
      type: Boolean,
      default: false
    },
    labelBtn: {
      type: String
    }
  },

  data() {
    return {
      form: {
        id_tipo_dado: "R"
      },
      formDates: {},
      picker: this.formatPickerForEditing(this.tipoFechamento),
      dates: this.formatDatesForEditing(this.tipoFechamento),
      semanas: []
    };
  },

  watch: {
    tipoFechamento(value) {
      this.form = value;
    }
  },

  computed: {
    disabledDatePicker() {
      return this.form.id_tipo_dado ? false : true;
    }
  },

  methods: {
    getPeriodoFechamento(value) {
      this.formDates = value;
      console.log("this.formDates", this.formDates);
    },
    getPeriodoFechamentoSemanal(value) {
      console.log("getPeriodoFechamentoSemanal", value);
    },
    close() {
      this.$emit("close");
      this.$refs.form.reset();
    },

    async save() {
      if (this.form.id_tipo_dado === "C") {
        this.cadastroFechamentoSemanal();
      } else {
        const validateForm = this.$refs.form.validate();
        if (validateForm) {
          await comissao()
            .tipoFechamento()
            .store(this.formatFormData(), {
              notification: true,
              message: "Fechamento Cadastrado com Sucesso!"
            });
          this.close();
        }
      }
    },

    async cadastroFechamentoSemanal() {
      const validateForm = this.$refs.form.validate();
      if (validateForm) {
        let promissesSemanas = this.semanas.map(item => {
          let dataMes= new Date(item.data_final);
          let mes = dataMes.getMonth() + 1;
          let dataAno = new Date(item.data_final);
          let ano = dataAno.getFullYear();
          return comissao()
            .tipoFechamento()
            .store({
              ...this.formatFormData(),
              data_inicio: item.data_inicio,
              data_final: item.data_final,
              mes: mes,
              ano: ano,
              mes_ref: mes,
              ano_ref: ano
            });
        });

        await Promise.all(promissesSemanas);
        this.close();
      }
    },

    getIntervals(event) {
      this.semanas = event.map(item => {
        return {
          data_inicio: item.start,
          data_final: item.end
        };
      });
    },

    monthSelected(event) {
      console.log("event", event);
    },

    formatTitleRangeDate(dates) {
      const arrDatesFormated = dates.map(stringDate => {
        const date = parse(stringDate, "yyyy-MM-dd", new Date());
        return format(date, "dd/MM/yyyy");
      });
      return arrDatesFormated.join(" - ");
    },
    formatTitleDatePicker(stringDate) {
      const date = parse(stringDate, "yyyy-MM", new Date());
      const stringDateFormated = format(date, "MMMM", { locale: ptBR });
      return stringDateFormated;
    },
    formatFormData() {
      const date = parse(
        this.form.dataFinal,
        "yyyy-MM-dd HH:mm:ss",
        new Date()
      );
      const mes = String(getMonth(date) + 1);
      const ano = String(getYear(date));
      const data = {
        id_tipo_dado: this.form.id_tipo_dado,
        id_tipo_evento: this.form.id_tipo_evento,
        data_inicio: this.form.dataInicio,
        data_final: this.form.dataFinal,
        ativo: this.form.ativo,
        mes,
        ano,
        mes_ref: mes,
        ano_ref: ano
      };

      console.log("data", data);
      
      return data;
    },
    formatDataInicioDataFimMesFechado() {
      const date = parse(this.picker, "yyyy-MM", new Date());
      this.form.dataInicio = format(date, "yyyy-MM-dd HH:mm:ss");
      this.form.dataFinal = format(lastDayOfMonth(date), "yyyy-MM-dd HH:mm:ss");
    },
    formatDataInicioDataFimSemanal() {
      const date = parse(this.picker, "yyyy-MM", new Date());
      this.form.dataInicio = format(date, "yyyy-MM-dd HH:mm:ss");
      this.form.dataFinal = format(lastDayOfMonth(date), "yyyy-MM-dd HH:mm:ss");
    },
    formatDataInicioDataFimMesRef() {
      const date = parse(this.picker, "yyyy-MM", new Date());
      this.form.dataInicio = format(
        setDate(subMonths(date, 1), 26),
        "yyyy-MM-dd HH:mm:ss"
      );
      this.form.dataFinal = format(setDate(date, 25), "yyyy-MM-dd HH:mm:ss");
    },
    formatDataInicioDataFim() {
      if (this.form.id_tipo_dado === "R") {
        this.formatDataInicioDataFimMesFechado();
      } else if (this.form.id_tipo_dado === "M") {
        this.formatDataInicioDataFimMesRef();
      } else if (this.form.id_tipo_dado === "X") {
        this.formatDataInicioDataFimRange();
      } else if (this.form.id_tipo_dado === "C") {
        this.formatDataInicioDataFimSemanal();
      }
    },
    formatDatesForEditing(tipoFechamento) {
      if (Object.keys(tipoFechamento).length > 0) {
        const dataInicioFormated = tipoFechamento.data_inicio.substr(0, 10);
        const dataFinalFormated = tipoFechamento.data_final.substr(0, 10);
        return [dataInicioFormated, dataFinalFormated];
      } else {
        return [
          new Date().toISOString().substr(0, 10),
          new Date().toISOString().substr(0, 10)
        ];
      }
    },
    formatDataInicioDataFimRange() {
      const arrDates = this.dates.map(date =>
        parse(date, "yyyy-MM-dd", new Date())
      );
      arrDates.sort(compareAsc);
      this.form.dataInicio = format(arrDates[0], "yyyy-MM-dd HH:mm:ss");
      this.form.dataFinal = format(
        arrDates[arrDates.length - 1],
        "yyyy-MM-dd HH:mm:ss"
      );
    },
    formatPickerForEditing(tipoFechamento) {
      if (Object.keys(tipoFechamento).length > 0) {
        const mesFormated =
          parseInt(tipoFechamento.mes) < 10
            ? `0${tipoFechamento.mes}`
            : tipoFechamento.mes;
        return `${tipoFechamento.ano}-${mesFormated}`;
      } else {
        return new Date().toISOString().substr(0, 7);
      }
    },

    async edit() {
      if (this.$refs.form.validate()) {
        if (this.$refs.form.validate()) {
          await comissao()
            .tipoFechamento(this.form.id_tipo_fechamento)
            .update(this.formatFormData(), {
              notification: true,
              message: "Fechamento Editado com Sucesso!"
            });
        }
      }
      this.close();
    }
  }
};
</script>
