<template>
  <v-date-picker
    v-model="dates"
    full-width
    elevation="1"
    locale="pt-BR"
    type="date"
    range
    :title-date-format="formatTitleRangeDate"
    @change="formatDataInicioDataFimRange()"
  ></v-date-picker>
</template>

<script>
import { parse, format, compareAsc } from "date-fns";
export default {

  props: {
    tipoDado: {
      type: String,
    }
  },

  data() {
    return {
      dates: [
        (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      ]
    };
  },

  watch: {
    tipoDado() {
      this.dates = [
        (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      ]
    }
  },

  methods: {
    formatTitleRangeDate(dates) {
      const arrDatesFormated = dates.map(stringDate => {
        const date = parse(stringDate, "yyyy-MM-dd", new Date());
        return format(date, "dd/MM/yyyy");
      });
      return arrDatesFormated.join(" - ");
    },

    formatDataInicioDataFimRange() {
      const arrDates = this.dates.map(date =>
        parse(date, "yyyy-MM-dd", new Date())
      );
      arrDates.sort(compareAsc);
      const dataInicio = format(arrDates[0], "yyyy-MM-dd HH:mm:ss");
      const dataFinal = format(
        arrDates[arrDates.length - 1],
        "yyyy-MM-dd HH:mm:ss"
      );

      const formattedDate = format(arrDates[0], "yyyy-MM");
      const mes = parseInt(formattedDate.split("-")[1]);
      const ano = parseInt(formattedDate.split("-")[0]);

      this.$emit("periodoFechamento", { 
        data_inicio: dataInicio,
        data_final: dataFinal,
        mes: mes,
        mes_ref: mes,
        ano: ano,
        ano_ref: ano  
      });
    }
  }
};
</script>

<style></style>
