<template>
  <v-date-picker
    v-model="picker"
    full-width
    elevation="1"
    locale="pt-BR"
    type="month"
    @change="formatDataInicioDataFimMesRef()"
  ></v-date-picker>
</template>

<script>
import { parse, format, setDate, subMonths } from "date-fns";
export default {

  props: {
    tipoDado: {
      type: String,
    }
  },

  data() {
    return {
      picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7)
    };
  },

  watch: {
    tipoDado() {
      this.picker = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7)
    }
  },

  methods: {
    formatDataInicioDataFimMesRef() {
      const date = parse(this.picker, "yyyy-MM", new Date());
      const dataInicio = format(setDate(subMonths(date, 1), 26), "yyyy-MM-dd HH:mm:ss");
      const dataFinal = format(setDate(date, 25), "yyyy-MM-dd HH:mm:ss");

      const formattedDate = format(date, "yyyy-MM");
      const mes = parseInt(formattedDate.split("-")[1]);
      const ano = parseInt(formattedDate.split("-")[0]);

      this.$emit("periodoFechamento", { 
        data_inicio: dataInicio,
        data_final: dataFinal,
        mes: mes,
        mes_ref: mes,
        ano: ano,
        ano_ref: ano  
      });
    }
  }
};
</script>

<style></style>
