<template>
  <v-container class="container-card">
    <v-card>
      <TablePeriodoFechamento />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import TablePeriodoFechamento from "@/components/parametros/fechamento/TablePeriodoFechamento";

export default {
  name: "periodo-fechamento",

  components: {
    TablePeriodoFechamento
  },

  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    })
  },

  mounted() {
    this.fetchView("Consulta Tipo Fechamento");
  }
};
</script>
