<template>
  <v-row>
    <v-col cols="12" md="6" lg="6">
      <v-date-picker
        v-model="selectedDates"
        full-width
        elevation="1"
        range
        multiple
        :events="events"
        :event-color="getEventColor"
        locale="pt-BR"
        :allowed-dates="allowedDates"
        @input="onDateSelect"
        :title-date-format="formatTitleDatePicker"
        :show-current="false"
        :class="disableNavigation ? '' : 'date-picker'"
      ></v-date-picker>
      <v-btn width="100%" color="warning" class="mt-3" @click="clearAllIntervals">Limpar Todos os Intervalos</v-btn>
    </v-col>
    <v-col cols="12" md="6" lg="6">
      <v-text-field
        class="mb-8"
        :value="formatDateRangeText(intervals[i])"
        :label="week.week"
        v-for="(week, i) in weeks"
        :key="i"
        readonly
        disabled
      >
        <template v-slot:prepend-inner>
          <v-icon :color="week.color">mdi-circle</v-icon>
        </template>
      </v-text-field>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      selectedDates: [],
      intervals: [],
      weeks: [
        { week: "Semana 1", color: "red" },
        { week: "Semana 2", color: "blue" },
        { week: "Semana 3", color: "green" },
        { week: "Semana 4", color: "orange" }
      ],
      color: ["red", "blue", "green", "orange"],
      disableNavigation: true
    };
  },

  watch: {
    selectedDates(value) {
      if (value.length === 2) {
        const startDate = new Date(value[0]);
        const endDate = new Date(value[1]);

        if (startDate > endDate) {
          value = [value[1], value[0]];
        }

        if (this.intervals.length < 4 && !this.hasIntersection(value)) {
          this.intervals.push({
            start: value[0],
            end: value[1],
            color: this.color[this.intervals.length]
          });
          this.disableNavigation = false;
        }

        this.selectedDates = [];
        this.emitIntervals();
      }
    }
  },

  computed: {
    events() {
      let events = [];
      this.intervals.forEach(interval => {
        let currentDate = new Date(interval.start);
        const endDate = new Date(interval.end);
        while (currentDate <= endDate) {
          events.push(this.formatDate(currentDate));
          currentDate.setDate(currentDate.getDate() + 1);
        }
      });
      return events;
    }
  },

  methods: {
    emitIntervals() {
      this.$emit("intervals", this.intervals);
      if (this.intervals.length > 0) {
        this.$emit("getMonth", this.intervals[0].start.split("-")[1]);
      }
    },

    allowedDates(val) {
      const date = new Date(val);

      if (this.intervals.length === 0) {
        return true;
      }

      const maxEndDate = new Date(Math.max(...this.intervals.map(i => new Date(i.end))));

      if (date <= maxEndDate) {
        return false;
      }

      return !this.intervals.some(interval => {
        const start = new Date(interval.start);
        const end = new Date(interval.end);
        return date >= start && date <= end;
      });
    },

    formatDateRangeText(interval) {
      const dateInitial = interval?.start.split("-").reverse().join("/");
      const dateFinal = interval?.end.split("-").reverse().join("/");
      if (dateInitial == null || dateFinal == null) return "";
      return `${dateInitial} ~ ${dateFinal}`;
    },

    formatDate(date) {
      return date.toISOString().substr(0, 10);
    },

    getEventColor(date) {
      for (let interval of this.intervals) {
        if (
          new Date(date) >= new Date(interval.start) &&
          new Date(date) <= new Date(interval.end)
        ) {
          return interval.color;
        }
      }
      return "";
    },

    onDateSelect(dates) {
      if (dates && dates.length > 0) {
        this.selectedDates = dates;
      }
    },

    formatTitleDatePicker() {
      return "Semanas disponíveis";
    },

    clearAllIntervals() {
      this.intervals = [];
      this.disableNavigation = true;
      this.emitIntervals();
    },

    hasIntersection(newInterval) {
      const [newStart, newEnd] = [new Date(newInterval[0]), new Date(newInterval[1])];
      return this.intervals.some(interval => {
        const [start, end] = [new Date(interval.start), new Date(interval.end)];
        return (newStart <= end && newEnd >= start);
      });
    }
  }
};
</script>
<style>
  .date-picker .v-btn--icon {
    pointer-events: none;
    opacity: 0.5;
  }
</style>
