<template>
  <v-menu :close-on-content-click="false" max-width="320" offset-x>
    <template v-slot:activator="{ on }">
      <FilterBottom v-on="on" />
    </template>

    <v-card class="pa-3">
      <v-container>
        <v-switch
          v-model="filters.ativo"
          true-value="S"
          false-value="N"
          :label="`${filters.ativo == 'S' ? 'Ativo' : 'Inativo'}`"
          @change="handleFilter()"
        ></v-switch>
        <BaseFilter
          label="Tipo Evento"
          item-text="tipo_evento"
          item-value="id_tipo_evento"
          service="comissaoService.tipoEvento"
          prepend-inner-icon="mdi-calendar-text"
          v-model="filters.id_tipo_evento"
          clearable
          @change="handleFilter()"
        />
        <BaseFilter
          label="Tipo Dado"
          v-model="filters.id_tipo_dado"
          service="comissaoService.tipoDados"
          item-text="descricao"
          item-value="id_tipo_dado"
          prepend-inner-icon="mdi-calendar-end"
          clearable
          :filters="{ ativa_campanha: 'S' }"
          :multiple="false"
          @change="handleFilter()"
        />
        <DatePickerMonth @date="assignDateFilter($event)" />
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
import BaseFilter from "@/components/shared/BaseSelectService";
import FilterBottom from "@/components/shared/bottons/FilterBottom";
import DatePickerMonth from "@/components/shared/DatePickerMonth";

export default {
  name: "Filtro",

  components: {
    BaseFilter,
    DatePickerMonth,
    FilterBottom
  },

  data() {
    return {
      filters: {
        ativo: "S"
      }
    };
  },

  methods: {
    assignDateFilter(date) {
      this.filters["ano_ref"] = date?.ano_ref;
      this.filters["mes_ref"] = date?.mes_ref;
      this.handleFilter();
    },

    handleFilter() {
      this.$emit("selectedFilters", this.filters);
    }
  }
};
</script>
